import React, {useState} from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {useQueryClient} from "@tanstack/react-query";

export const AddTasks = () => {
  const [taskNames, setTaskNames] = React.useState("");
  const [error, setError] = useState('');
  const queryClient = useQueryClient()

  const postTask = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');

    const formattedTaskNames = taskNames.split("\n")
      .map((taskName) => taskName.trim())
      .filter((taskName) => taskName.length > 0);
    const result = await bFetch(`${getApiUrl()}/tasks`, {
      method: "POST",
      body: JSON.stringify({
        names: formattedTaskNames,
      }),
    });

    setTaskNames("");
    if (result.status !== 200) {
      setError("Wtf! Oppgave ikke lagret?");
    }
    await queryClient.invalidateQueries({queryKey: ['tasks']});
  }

  return (
    <form onSubmit={postTask}>
      <textarea name="taskNames" id="taskNames" cols={30} rows={10}
                onChange={(event) => setTaskNames(event.target.value)} value={taskNames}
                placeholder="Legg til oppgaver her, separert med linjeskift!"/>
      <div>{error}</div>
      <button type="submit" disabled={!taskNames}>Legg til oppgaver</button>
    </form>
  )
}
