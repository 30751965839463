import React from "react";
import "../style.css";
import styled from "styled-components";
import {useQuery} from "@tanstack/react-query";
import {bFetch} from "../util/client";
import {getApiUrl} from "../util/hostname";
import {formatCoords} from "../util/mapCoords";
import {Bar} from "../models/bar";

export const PrettyBarList = () => {
  const bars = useQuery<Bar[]>({
    queryKey: ["bars"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/bars`);
      return result.json();
    }
  }).data ?? [];

  return (
    <div>
      <h2> Bars </h2>
      <table>
        <tbody>
        {bars.map((bar, i) => {
          return (
            <BarTR id={"li" + bar.name} key={i}>
              <BarTD>{formatCoords(bar)}</BarTD>
              <BarTD> • </BarTD>
              <BarTD> {bar.name}</BarTD>
            </BarTR>
          );
        })}
        </tbody>
      </table>
    </div>
  );
};

const BarTR = styled.tr`
  td:nth-child(1) {
    color: #3d5c75;
    font-weight: 600;
  }
`;
const BarTD = styled.td`
  padding: 7px 2px;
`;
