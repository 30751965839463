import React, {useState} from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {useQueryClient} from "@tanstack/react-query";

export const AddAccount = () => {
  const queryClient = useQueryClient()
  const [name, setAccountName] = useState('');
  const [username, setAccountUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');
  const [error, setError] = useState('');

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const result = await bFetch(`${getApiUrl()}/account`, {
      method: "POST",
      body: JSON.stringify({
        username,
        name,
        password,
        role,
      }),
    });

    if (result.status === 200) {
      setError('Konto lagt til!');
      queryClient.invalidateQueries({queryKey: ['accounts']});
    } else {
      setError('Wtf! Noe gikk feil?');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-row">
        <label htmlFor="name">
          Navn:
        </label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(event) => setAccountName(event.target.value)}
        />
      </div>

      <div className="form-row">
        <label htmlFor="username">
          Brukernavn:
        </label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={(event) => setAccountUsername(event.target.value)}
        />
      </div>

      <div className="form-row">
        <label htmlFor="password">
          Passord:
        </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>

      <div className="form-row">
        <label htmlFor="role">
          Rolle:
        </label>
        <select id="role" value={role} onChange={(event) => setRole(event.target.value)}>
          <option value="user">Spiller</option>
          <option value="admin">Admin</option>
        </select>
      </div>

      <div>{error}</div>

      <button type="submit">Legg til konto</button>
    </form>
  );
}
