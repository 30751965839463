import React from "react";
import "../style.css";
import {getApiUrl} from "../util/hostname";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {bFetch} from "../util/client";
import {Ship} from "../models/ship";
import {mapToLetter, formatCoords} from "../util/mapCoords";

export const ShipList = () => {
  const ships: Ship[] = useQuery({
    queryKey: ['ships'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/ships`) //
      return result.json();
    }
  }).data ?? [];

  return (
    <div className="ships">
      {
        ships.sort((a, b) => a.id - b.id).map((ship) =>
          (
            <UserShip ship={ship} key={ship.id}></UserShip>
          ))
      }
    </div>
  );
};

const UserShip = ({ship}: { ship: Ship }) => {
  const queryClient = useQueryClient()

  const deleteShip = async (id: number) => {
    const result = await bFetch(`${getApiUrl()}/ships/${id}`, {
      method: "DELETE",
    })
    if (result.status !== 200) {
      alert("Wtf! Skipet ble ikke slettet?");
    }
    await queryClient.invalidateQueries({queryKey: ['ships']});
  }

  return (
    <div className="shipRow" key={ship.id}>
      <div className="ship">
        <div className="">Eierlag: {ship.team_name}</div>
        <div className="">Koordinat: {formatCoords(ship)}</div>
        <div className="">Status: {ship.status}</div>
      </div>
      <button onClick={() => deleteShip(ship.id)}>Slett skip</button>
    </div>
  )
}
