import React from "react";
import "../style.css";
import {getApiUrl} from "../util/hostname";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {bFetch} from "../util/client";

export interface Account {
  id: number;
  name: string;
  username: string;
  role: "admin" | "user";
  color: string;
}

export const AccountList = () => {

  const accounts: Account[] = useQuery({
    queryKey: ['accounts'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/accounts`) //
      return result.json();
    }
  }).data ?? [];

  return (
    <div className="accounts">
      {
        accounts.sort((a, b) => a.id - b.id).map((account) =>
          (
            <UserAccount account={account} key={account.id}></UserAccount>
          ))
      }
    </div>
  );
};

const UserAccount = ({account}: { account: Account }) => {
  const queryClient = useQueryClient()
  const [name, setAccountName] = React.useState(account.name);
  const [color, setAccountColor] = React.useState(account.color);

  const deleteAccount = async (id: number) => {
    const result = await bFetch(`${getApiUrl()}/accounts/${id}`, {
      method: "DELETE",
    })
    if (result.status !== 200) {
      alert("Wtf! Kontoen ble ikke slettet?");
    }
    await queryClient.invalidateQueries({queryKey: ['accounts']});
  }

  const setName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(event.target.value);
  }

  const setColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountColor(event.target.value);
  }

  const updateAccount = async () => {
    const body: { name?: string, color?: string } = {};
    if (name !== account.name) {
      body.name = name;
    }
    if (color !== account.color) {
      body.color = color;
    }
    await bFetch(`${getApiUrl()}/accounts/${account.id}`, {
      method: "PATCH",
      body: JSON.stringify(body),
    });

    await queryClient.invalidateQueries({queryKey: ['accounts']});
  }
  return (
    <div className="accountRow">
      <div className="account">
        <div className="">ID: {account.id}</div>
        <label htmlFor="name-input">
          Navn:
          <input id="name-input" className="" value={name} onChange={setName}/>
        </label>
        <div className="">Brukernavn: {account.username}</div>
        <div className="">Rolle: {account.role}</div>
        <label htmlFor="color-input">
          Farge:
          <input type="color" id="color-input" className="" value={color} onChange={setColor}/>
        </label>
      </div>
      <button onClick={updateAccount}>Oppdater konto</button>
      <button onClick={() => deleteAccount(account.id)}>Slett konto</button>
    </div>
  )
}
