import React from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {useQuery} from "@tanstack/react-query";
import {Ship} from "../models/ship";
import {Account} from "./AccountList";

interface Status {
  [teamId: number]: {
    teamName: string;
    total: number;
    sunk: number;
  }
}

const getTeamStatus = (ships: Ship[]) => {
  return ships.reduce<Status>((acc, ship) => {
    if (!acc[ship.team_id]) {
      acc[ship.team_id] = {
        teamName: ship.team_name,
        total: 0,
        sunk: 0
      };
    }
    acc[ship.team_id].total++;
    acc[ship.team_id].sunk += ship.status === "sunk" ? 1 : 0;
    return acc;
  }, {});
}

const getAccount = (accounts: Account[], teamId: string) => {
  return accounts.find(account => account.id === parseInt(teamId));
}

export const TeamList = () => {
  const ships: Ship[] = useQuery({
    queryKey: ['ships'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/ships`) //
      return result.json();
    }
  }).data ?? [];

  const accounts: Account[] = useQuery({
    queryKey: ['accounts'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/accounts`) //
      return result.json();
    }
  }).data ?? [];


  const statuses = getTeamStatus(ships);

  return (
    <div className="teamlist">
      <h1>Lagstatus</h1>
      {
        Object.entries(statuses).map(([teamId, status]) => (
          <span className="teamrow">
            <div className="circle"
                 style={{backgroundColor: getAccount(accounts, teamId)?.color ?? "white"}}></div>
            <h3 key={teamId}>
              {`${status.teamName}: ${status.total - status.sunk}/${status.total}`}
            </h3>
          </span>
        ))
      }
    </div>
  );
}
