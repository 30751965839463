import React, {useEffect} from "react";
import {useQuery, useMutation} from "@tanstack/react-query";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";

export const DeadlinesForm = () => {
  const deadlines = useQuery({
    queryKey: ["deadlines"], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/deadlines`);
      return result.json();
    }
  }).data;
  const saveDeadlines = useMutation({
    mutationKey: ["deadlines"],
    mutationFn: async (deadlines: { [key: string]: string }) => {
      const result = await bFetch(`${getApiUrl()}/deadlines`, {
        method: "POST",
        body: JSON.stringify({
          gameEnd: deadlines.gameEnd,
          silentNightStart: deadlines.silentNightStart,
          silentNightEnd: deadlines.silentNightEnd,
        })
      });
      return result.text();
    }
  });

  const [gameEnd, setGameEnd] = React.useState("");
  const [silentNightStart, setSilentNightStart] = React.useState("");
  const [silentNightEnd, setSilentNightEnd] = React.useState("");

  useEffect(() => {
    if (deadlines) {
      setGameEnd(deadlines.gameEnd);
      setSilentNightStart(deadlines.silentNightStart);
      setSilentNightEnd(deadlines.silentNightEnd);
    }
  }, [deadlines])

  const setValue = (event: React.ChangeEvent<HTMLInputElement>, type: "game" | "silent-start" | "silent-end") => {
    const value = event.target.value;
    if (type === "game") {
      setGameEnd(value);
    } else if (type === "silent-start") {
      setSilentNightStart(value);
    } else {
      setSilentNightEnd(value);
    }
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    saveDeadlines.mutate({
      gameEnd,
      silentNightStart,
      silentNightEnd,
    });
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-row">
          <label htmlFor="game-end">
            Spillets slutt
          </label>
          <input type="time" min="10:00" max="23:00" name="game-end" id="game-end"
                 onChange={(event) => setValue(event, "game")} value={gameEnd}/>
        </div>

        <div className="form-row">
          <label htmlFor="silent-night-start">
            Stille natt start
          </label>
          <input type="time" min="10:00" max="23:00" name="silent-night-start" id="silent-night-start"
                 onChange={(event) => setValue(event, "silent-start")} value={silentNightStart}/>
        </div>

        <div className="form-row">
          <label htmlFor="silent-night-end">
            Stille natt slutt
          </label>
          <input type="time" min="10:00" max="23:00" name="silent-night-end" id="silent-night-end"
                 onChange={(event) => setValue(event, "silent-end")} value={silentNightEnd}/>
        </div>

        <button type="submit">Lagre tidsfrister</button>
      </form>
    </div>
  )
}
