import React from "react";
import "../style.css";
import styled from "styled-components";
import {Deadlines} from "../Components/Deadlines";
import {PrettyBarList} from "../Components/PrettyBarList";
import {IllustratedBoard} from "../Components/IllustratedBoard";
import {TeamList} from "../Components/TeamList";

export const BMap = () => {
  return (
    <MapDiv>
      <Deadlines/>
      <Row>
        <TeamList/>
        <IllustratedBoard/>
      </Row>
      <PrettyBarList/>
    </MapDiv>
  );
};

const MapDiv = styled.div`
  padding: 24px;
  margin-bottom: 30vh;
  scrollbehavior: smooth;
  block: start;
`;

const Row = styled.div`
  display: flex;
`;
