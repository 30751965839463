import React, {useState} from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {useQueryClient} from "@tanstack/react-query";
import {mapToNumber} from "../util/mapCoords";
import {ShipPosition} from "../models/ship";

const mapInputToShips = (input: string): ShipPosition[] => {
  return input.split("\n").map((line) => {
    const [id, coord] = line.split(" ");
    return {
      team_id: parseInt(id),
      row: mapToNumber(coord[0]),
      col: parseInt(coord[1]),
      status: "alive",
    };
  });
}

export const AddShips = () => {
  const [shipInput, setShipInput] = React.useState("");
  const [error, setError] = useState('');
  const queryClient = useQueryClient()

  const postShips = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');

    const result = await bFetch(`${getApiUrl()}/ships`, {
      method: "POST",
      body: JSON.stringify({
        ships: mapInputToShips(shipInput),
      }),
    });

    setShipInput("");
    if (result.status !== 200) {
      setError("Wtf! Skip ikke lagret?");
    }
    await queryClient.invalidateQueries({queryKey: ['ships']});
  }

  return (
    <form onSubmit={postShips}>
      <textarea name="shipInput" id="shipInput" cols={30} rows={10}
                onChange={(event) => setShipInput(event.target.value)} value={shipInput}
                placeholder="Legg til skipskoordinater her, én per linje, format: <lag-ID> <rad><kol>. Eks: 3 C3"/>
      <div>{error}</div>
      <button type="submit" disabled={!shipInput}>Legg til skip</button>
    </form>
  )
}
