import React, {useState} from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";

export const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwordene er ikke like');
      return;
    }

    const result = await bFetch(`${getApiUrl()}/change-password`, {
      method: "POST",
      body: JSON.stringify({
        oldPassword,
        newPassword,
      }),
    });

    if (result.status === 200) {
      setError('Passord endret!');
    } else {
      setError('Wtf! Passord ikke endret?');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-row">
        <label>
          Gammelt passord:
        </label>
        <input
          type="password"
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
        />
      </div>

      <div className="form-row">
        <label>
          Nytt passord:
        </label>
        <input
          type="password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
      </div>

      <div className="form-row">
        <label>
          Bekreft nytt passord:
        </label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
      </div>

      <div>{error}</div>
      <button type="submit">Endre passord</button>
    </form>
  );
}
