import React from "react";
import "../style.css";
import {TaskContainer} from "../Components/TaskContainer";

export const TaskPage = () => {
  return (
    <TaskContainer/>
  );
}

