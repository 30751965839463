import React, {useState} from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {useQueryClient} from "@tanstack/react-query";
import {Bar} from "../models/bar";
import {mapToNumber} from "../util/mapCoords";

export const mapInputToBars = (text: string): Omit<Bar, "id">[] => {
  return text.split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0)
    .map((line) => {
      const parts = line.split(" ");
      const l = parts.length - 1;
      return {
        name: parts.slice(0, l).join(" "),
        row: mapToNumber(parts[l][0]),
        col: parseInt(parts[l][1]),
      };
    });
}

export const AddBars = () => {
  const [barInput, setBarInput] = React.useState("");
  const [error, setError] = useState('');
  const queryClient = useQueryClient()

  const postBars = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');

    const result = await bFetch(`${getApiUrl()}/bars`, {
      method: "POST",
      body: JSON.stringify({
        bars: mapInputToBars(barInput),
      }),
    });

    setBarInput("");
    if (result.status !== 200) {
      setError("Wtf! Barer ikke lagret?");
    }
    await queryClient.invalidateQueries({queryKey: ['bars']});
  }

  return (
    <form onSubmit={postBars}>
      <textarea name="barInput" id="barInput" cols={30} rows={10}
                onChange={(event) => setBarInput(event.target.value)} value={barInput}
                placeholder="Legg til barer her, format: <navn> <rad><kol>. Eks: Bar Boca C3"/>
      <div>{error}</div>
      <button type="submit" disabled={!barInput}>Legg til barer</button>
    </form>
  )
}
