import React from "react";
import "../style.css";
import {getApiUrl} from "../util/hostname";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {bFetch} from "../util/client";
import {Task, CompletedTask} from "../models/task";

export const TaskContainer = ({showRemove = false}: { showRemove?: boolean }) => {
  const tasks: Task[] = useQuery({
    queryKey: ['tasks'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/tasks`) //
      return result.json();
    }
  }).data ?? [];
  const completedTasks: CompletedTask[] = useQuery({
    queryKey: ['completedTasks'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/completed-tasks`) //
      return result.json();
    }
  }).data ?? [];
  const tasksUncompleted: Task[] = [];
  const tasksCompleted: Task[] = [];
  tasks.forEach((task) => {
    const completed = completedTasks.find((completedTask) => completedTask.task_id === task.id);
    if (completed) {
      tasksCompleted.push(task);
    } else {
      tasksUncompleted.push(task);
    }
  });

  return (
    <div className="bb_tasks">
      <TaskList title="Ledige oppgaver" tasks={tasksUncompleted} className="taskItem" showRemove={showRemove}/>
      <TaskList title="Fullførte oppgaver" tasks={tasksCompleted} className="completedTask" showRemove={showRemove}/>
    </div>
  );
};

const TaskList = ({
                    title,
                    tasks,
                    className,
                    showRemove,
                  }: {
  title: string;
  tasks: Task[];
  className: string;
  showRemove: boolean;
}) => {
  const queryClient = useQueryClient()

  const removeTask = async (id: number) => {
    const result = await bFetch(`${getApiUrl()}/tasks/${id}`, {
      method: "DELETE",
    });
    if (result.status !== 200) {
      alert("Wtf! Oppgaven ble ikke slettet?");
    }
    await queryClient.invalidateQueries({queryKey: ['tasks']});
  }

  return (
    <div>
      <h3> {title} </h3>
      <ul className="taskList">
        {tasks.map((task) =>
          (
            <li className={className}>
              <span>
              {`${task.id}: ${task.name}`}
              </span>
              {showRemove &&
                  <button onClick={() => removeTask(task.id)}>Remove</button>
              }
            </li>
          ))}
      </ul>
    </div>
  );
};
