export const mapToLetter = (row: number) => {
  return String.fromCharCode(64 + row);
}

export const mapToNumber = (row: string) => {
  return row.toUpperCase().charCodeAt(0) - 64;
}

export const formatCoords = ({row, col}: {row: number, col: number}) => {
  return `${mapToLetter(row)}${col}`;
}
