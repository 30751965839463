import ErrorPage from "./screens/error-page";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { BHeader } from "./Components/BHeader";
import { BMap } from "./screens/BMap";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Login } from "./screens/Login";
import { useAuth } from "./hooks/useAuth";
import { SettingsPage } from "./screens/SettingsPage";
import { TaskPage } from "./screens/TaskPage";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export const App = () => {
  const { auth } = useAuth();
  if (!auth) {
    return <Login />;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BMap />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/tasks",
      element: <TaskPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/settings",
      element: <SettingsPage />,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <ReactQueryDevtools />
        <BHeader />
        <RouterProvider router={router} />
      </>
    </QueryClientProvider>
  );
};
