import React from "react";
import {useQuery} from "@tanstack/react-query";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {useInterval} from "usehooks-ts";

const timeToEpoch = (time: string): number => {
  const [hour, minute] = time.split(":");
  return new Date().setHours(parseInt(hour), parseInt(minute), 0, 0);
}

const calculateTimeLeft = (time: string): number => {
  const epoch = timeToEpoch(time);
  return (epoch - Date.now());
}

function formatTime(ms: number): string {
  let seconds = ms / 1000;
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.round(seconds % 60);
  const parts = [];
  if (hours > 0) {
    parts.push(hours);
  }
  parts.push(minutes.toString().padStart(2, "0"));
  parts.push(seconds.toString().padStart(2, "0"));
  return parts.join(":");
}

export const Deadlines = () => {
  const deadlines = useQuery({
    queryKey: ["deadlines"], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/deadlines`);
      return result.json();
    }
  }).data;

  const [gameEnd, setGameEnd] = React.useState(0);
  const [silentNightStart, setSilentNightStart] = React.useState(-1);
  const [silentNightEnd, setSilentNightEnd] = React.useState(-1);


  useInterval(() => {
      if (!deadlines || Object.keys(deadlines).length === 0) {
        return;
      }
      setGameEnd(Math.max(calculateTimeLeft(deadlines.gameEnd), 0));
      setSilentNightStart(calculateTimeLeft(deadlines.silentNightStart));
      setSilentNightEnd(calculateTimeLeft(deadlines.silentNightEnd));
    },
    1000,
  )

  return (
    <div className="deadlines">
      <h2>Spillets slutt: {formatTime(gameEnd)}</h2>
      {
        silentNightStart > 0 && (
          <h2>Stille natt: {formatTime(silentNightStart)}</h2>
        )
      }
      {
        silentNightStart < 0 && silentNightEnd > 0 && (
          <h2>Stille natt slutt: {formatTime(silentNightEnd)}</h2>
        )
      }
    </div>
  )
}
