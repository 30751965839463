import {useAuth} from "../hooks/useAuth";
import React from "react";
import {AddTasks} from "../Components/AddTasks";
import {TaskContainer} from "../Components/TaskContainer";
import {AddBars} from "../Components/AddBars";
import {BarList} from "../Components/BarList";
import {AddAccount} from "../Components/AddAccount";
import {ChangePassword} from "../Components/ChangePassword";
import {DeadlinesForm} from "../Components/DeadlinesForm";
import {AccountList} from "../Components/AccountList";
import {ShipList} from "../Components/ShipList";
import {AddShips} from "../Components/AddShips";
import {bFetch} from "../util/client";
import {getApiUrl} from "../util/hostname";

export const SettingsPage = () => {
  const {logout} = useAuth();

  const onLogout = async () => {
    await logout();
  };

  const onReset = async () => {
    await bFetch(`${getApiUrl()}/reset`, {
      method: "POST",
    });
    await logout();
  }

  return (
    <div className="settings">
      <div className="section">
        <h2>Oppgaver</h2>
        <TaskContainer showRemove={true}/>
      </div>
      <div className="section">
        <h2>Legg til oppgaver</h2>
        <AddTasks/>
      </div>
      <div className="section">
        <h2>Barer</h2>
        <BarList/>
      </div>
      <div className="section">
        <h2>Legg til barer</h2>
        <AddBars/>
      </div>
      <div className="section">
        <h2>Kontoer</h2>
        <AccountList/>
      </div>
      <div className="section">
        <h2>Legg til konto</h2>
        <AddAccount/>
      </div>
      <div className="section">
        <h2>Skip</h2>
        <ShipList/>
      </div>
      <div className="section">
        <h2>Legg til skip</h2>
        <AddShips/>
      </div>
      <div className="section">
        <h2>Tidspunkter</h2>
        <DeadlinesForm/>
      </div>
      <div className="section">
        <h2>Endre passord</h2>
        <ChangePassword/>
      </div>
      <div className="section">
        <h2>Logg ut</h2>
        <button onClick={onLogout}>Logg ut</button>
      </div>
      <div className="section">
        <h2>RESET SPILLET</h2>
        <button onClick={onReset}>RESET</button>
      </div>
    </div>
  );
}
