import React, {ReactNode, useEffect} from "react";
import "../style.css";
import styled from "styled-components";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {readPersistedAuth} from "../hooks/useAuth";
import {bFetch} from "../util/client";
import {getApiUrl, getWebsocketUrl} from "../util/hostname";
import {Ship} from "../models/ship";
import {mapToLetter} from "../util/mapCoords";
import {CompletedTask} from "../models/task";

const cellSize = `${window.innerWidth / 14}px`;

export const IllustratedBoard = () => {
  const ships: Ship[] =
    useQuery({
      queryKey: ["ships"],
      queryFn: async () => {
        const result = await bFetch(`${getApiUrl()}/ships`);
        return result.json();
      },
    }).data ?? [];
  const completedTasks: CompletedTask[] = useQuery({
    queryKey: ['completedTasks'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/completed-tasks`) //
      return result.json();
    }
  }).data ?? [];

  const queryClient = useQueryClient();

  useEffect(() => {
    console.log("Connecting...");
    const auth = readPersistedAuth();

    const ws = new WebSocket(`${getWebsocketUrl()}/ws?auth=${auth?.token}`);
    ws.onopen = () => {
      console.log("Connected");
    };

    ws.onmessage = async function (event) {
      console.log("DATA:", event.data);
      try {
        const data = JSON.parse(event.data);
        if (data.messageType === "completedTask") {
          await queryClient.invalidateQueries(["completedTasks", "ships"]);
        }
      } catch (err) {
        console.log(err);
      }
    };

    return () => ws.close();
  }, [queryClient]);

  const table = [<ColumnLabels key={0}/>];
  for (let i = 1; i <= 7; i++) {
    const row = [<RowLabel>{mapToLetter(i)}</RowLabel>];
    for (let j = 1; j <= 7; j++) {
      const significantTask = getSignificantTask(completedTasks, i, j);
      const matchingShips = findShips(ships, i, j);
      row.push(
        <StyledSquare type={significantTask?.type} key={`${i}-${j}`}>
          <RowColors>
            {matchingShips.length > 0 &&
              matchingShips.map(({color}) => {
                return <TeamColor teamColor={color}/>;
              })}
          </RowColors>
        </StyledSquare>
      );
    }
    table.push(<div key={i} style={{display: "flex"}}>{row}</div>);
  }
  return (
    <div>
      {table}
    </div>
  );
};

function RowLabel({children}: { children: ReactNode }) {
  return (
    <div className="rowLabel" style={{width: cellSize, height: cellSize, lineHeight: cellSize}}>
      {children}
    </div>
  )
}

function ColumnLabel({children}: { children: ReactNode }) {
  return (
    <div className="columnLabel" style={{width: cellSize, height: cellSize, lineHeight: cellSize}}>
      {children}
    </div>
  )
}

function ColumnLabels() {
  return (
    <div className="columnLabels">
      <ColumnLabel key={0}>{' '}</ColumnLabel>
      {[1, 2, 3, 4, 5, 6, 7].map((number) => (
        <ColumnLabel key={number}>{number}</ColumnLabel>
      ))}
    </div>
  )
}


function getSignificantTask(completedTasks: CompletedTask[], row: number, col: number): CompletedTask | undefined {
  const tasks = completedTasks.filter((task) => task.row === row && task.col === col);
  const attack = tasks.find((task) => task.type === "attack");
  if (attack) {
    return attack;
  }
  return tasks[0];
}

function findShips(ships: Ship[], row: number, col: number): Ship[] {
  return ships.filter((ship) => ship.row === row && ship.col === col);
}

const TeamColor = styled.span<{ teamColor: string }>`
  height: 25px;
  width: 25px;
  background-color: ${(props) => (props.teamColor ? props.teamColor : "#ffff")};
  border-radius: 50%;
  display: inline-block;
  margin: 4px;
`;

const RowColors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const getColorForType = (type?: "attack" | "preserve") => {
  switch (type) {
    case "attack":
      return "palevioletred";
    case "preserve":
      return "#F2DA93";
    default:
      return "#63829c";
  }
}

const StyledSquare = styled.div<{
  type?: "attack" | "preserve";
}>`
  background-color: ${(props) => getColorForType(props.type)};
  height: ${cellSize};
  width: ${cellSize};
  border-radius: 2px;
  margin: 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

