import {useState, useEffect} from "react";
import {getApiUrl} from "../util/hostname";

interface Auth {
  token: string;
  username: string;
}

const TOKEN_KEY = "token";
const ACCOUNT_ID_KEY = "username";

export const readPersistedAuth = (): Auth | null => {
  const token = localStorage.getItem(TOKEN_KEY);
  const username = localStorage.getItem(ACCOUNT_ID_KEY);
  if (!token || !username) {
    return null;
  }
  return {token, username};
}

const persistAuth = (auth: Auth | null) => {
  if (!auth) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ACCOUNT_ID_KEY);
    return;
  }
  localStorage.setItem(TOKEN_KEY, auth.token);
  localStorage.setItem(ACCOUNT_ID_KEY, auth.username);
}

export const useAuth = () => {
  const [auth, setAuth] = useState<Auth | null>(null);

  useEffect(() => {
    const auth = readPersistedAuth();
    if (auth) {
      setAuth(auth);
    }
  }, []);

  const login = async (username: string, password: string) => {
    const response = await fetch(`${getApiUrl()}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({username, password}),
    });
    if (response.status !== 200) {
      alert("Login failed");
      return;
    }
    const {token, role} = await response.json();
    if (role !== "admin") {
      alert("You need admin privileges to access this page");
      return;
    }
    persistAuth({token, username});
    setAuth({token, username});
    window.location.reload();
  }

  const logout = async () => {
    await fetch(`${getApiUrl()}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }, body: JSON.stringify(auth),
    });
    persistAuth(null);
    setAuth(null);
    window.location.reload();
  }

  return {
    auth,
    login,
    logout,
  };
}
