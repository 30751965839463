import {readPersistedAuth} from "../hooks/useAuth";

export const bFetch = async (url: string, options: RequestInit = {}) => {
  const auth = readPersistedAuth();
  const headers = auth ? {
    ...options.headers,
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth?.token}`,
  } : options.headers;

  return fetch(url, {
    ...options,
    headers: headers
  });
}
