import React from "react";
import "../style.css";
import {getApiUrl} from "../util/hostname";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {bFetch} from "../util/client";
import {mapToLetter, formatCoords} from "../util/mapCoords";
import {Bar} from "../models/bar";

export const BarList = () => {
  const queryClient = useQueryClient()

  const bars: Bar[] = useQuery({
    queryKey: ['bars'], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/bars`) //
      return result.json();
    }
  }).data ?? [];

  const deleteBar = async (id: number) => {
    const result = await bFetch(`${getApiUrl()}/bars/${id}`, {
      method: "DELETE",
    })
    if (result.status !== 200) {
      alert("Wtf! Baren ble ikke slettet?");
    }
    await queryClient.invalidateQueries({queryKey: ['bars']});
  }

  return (
    <div className="bars">
      {
        bars.map((bar) =>
          (
            <div className="barRow" key={bar.id}>
              <div className="bar">
                <div className="barCoord">{formatCoords(bar)}</div>
                <div className="barName">{bar.name}</div>
              </div>
              <button onClick={() => deleteBar(bar.id)}>Slett bar</button>
            </div>
          ))
      }
    </div>
  );
};

