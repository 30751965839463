/*
Returns the API URL including slash at the end
 */
export const getApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    return "http://localhost:3000";
  }
  return `https://${hostname.replace("live", "api")}`;
};

export const getWebsocketUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    return "ws://localhost:3000";
  }
  return `wss://${hostname.replace("live", "api")}`;
};
